import axios from "axios"
//export const baseURL = "https://localhost:7102/api/v1/"
export const baseURL = "https://devapi.kyakhana.com/api/v1/"
//export const baseURL = "https://api.kyakhana.com/api/v1/"
export default {
    _post: axios.post,
    _get: axios.get,
    _put: axios.put,
    _delete: axios.delete,
    _request: axios.request,
    _patch: axios.patch
}
